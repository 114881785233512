import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";

import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";
import moment from "moment";
import API_URL from "../../../Config/config";

// Function to generate site name link
const generateRunLink = (site_name, runId, unitId) => {
  return (
    <MDTypography
      component={Link}
      to={`/runs/runs-history/${runId}/unit-history/${unitId}`} 
      variant="caption"
      color="primary"
      fontWeight="medium"
      sx={{
        textDecoration: "underline",
        color: "blue",
        cursor: "pointer",
      }}
    >
      {site_name}
    </MDTypography>
  );
};


function RunHistory() {
  const { runId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [units, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchData = async () => {
    try {
      // Make API call using runId from URL
      const response = await axios.get(`${API_URL}/units/${runId}`);
      console.log("Using API URL in fetchData:", API_URL);
      console.log(response.data.units);
      const fetchedData = Array.isArray(response.data.units) ? response.data.units : [];
      setData(fetchedData);
      setTotalRecords(fetchedData.length);
    } catch (error) {
      console.error("Error fetching units", error);
    }
  };

  useEffect(() => {
    if (runId) {
      fetchData();
    }
  }, [runId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      Header: "SB Unit Name",
      accessor: "site_name",
      width: "20%",
      align: "left",
      Cell: ({ row }) => generateRunLink(row.values.site_name, runId,row.original.unitId),
    },
    { Header: "IP Address", accessor: "IP_Address", align: "left" },
    { Header: "Site Group", accessor: "group_name", align: "center" },
    { Header: "Latitude", accessor: "latitude", align: "center" },
    { Header: "Longitude", accessor: "longitude", align: "center" },
    {
      Header: "Run Date",
      accessor: "createdAt",
      align: "center",
      Cell: ({ row }) => moment(row.values.createdAt).format("MMM D, YYYY HH:mm"), 
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDTypography
                  variant="h5"
                  color="Black"
                  sx={{
                    marginLeft: "25px",
                    marginTop: "10px",
                  }}
                >
                  Runs History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: columns, rows: Array.isArray(units) ? units : [] }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {/* Pagination below the table */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RunHistory;
