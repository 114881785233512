import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Grid, Card, IconButton, Modal, Box, Typography, CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";
import axios from "axios";
import API_URL from "../../../Config/config";

const columns = [
  { Header: "Site Name", accessor: "site_name", align: "left" },
  { Header: "Stage", accessor: "stage", align: "left" },
  { Header: "Results", accessor: "results", align: "center" },
  { Header: "Run Date", accessor: "run_date", align: "center" },
  { Header: "Status", accessor: "status", align: "center" },
];

function UnitHistory() {
  const { unitId } = useParams();
  const [data, setData] = useState([]); // Will now store only history array
  const [siteName, setSiteName] = useState(""); // Store site name separately
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUnitHistory();
  }, []);

  const fetchUnitHistory = async () => {
    try {
      const response = await axios.get(`${API_URL}/units/unit-history/${unitId}`);
      const result = response.data;

      setSiteName(result.site_name || "N/A"); // Store site name separately
      setData(Array.isArray(result.history) ? result.history : []); // Ensure data is always an array
    } catch (error) {
      console.error("Error fetching unit history:", error);
      setData([]); // Set empty array on error
    }
  };

  const getColorForStatus = (status) => (status === "success" ? "green" : "red");

  const handleInfoClick = async (result) => {
    if (result === "Failed") {
      setIsLoading(true);
      setOpenModal(true);
      try {
        const response = await axios.get("http://localhost:5000/api/failure-reason"); // Replace with actual API URL
        setMessage(response.data.message || "This test has failed. Please check the logs for details.");
      } catch (error) {
        setMessage("Failed to load details.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDTypography variant="h6" color="Black" sx={{ marginLeft: "25px", marginTop: "10px" }}>
                  Unit History
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: columns,
                    rows: data.map((row) => ({
                      site_name: siteName, // Use stored site name
                      stage: row.stage,
                      results: row.response,
                      run_date: new Date(row.timestamp).toLocaleString(), // Convert timestamp to readable format
                      status: (
                        <span style={{ color: getColorForStatus(row.status), fontWeight: "bold" }}>
                          {row.status}
                        </span>
                      ),
                    })),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Modal for showing details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            padding: 4,
            borderRadius: 2,
            boxShadow: 24,
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography id="modal-title" variant="h6">
            Information
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, textAlign: "center" }}>
            {isLoading ? <CircularProgress /> : message}
          </Typography>
          <button
            onClick={handleCloseModal}
            style={{
              marginTop: "20px",
              padding: "10px",
              backgroundColor: "#1976d2",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100%",
            }}
            disabled={isLoading}
          >
            Close
          </button>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default UnitHistory;
